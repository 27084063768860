import React from "react";

import {
  ChakraBaseProvider,
  extendBaseTheme,
  theme as chakraTheme,
  useBreakpointValue,
  extendTheme,
  Box,
  Flex,
  Heading,
  Image,
  Card,
  Center,
  Spacer,
  Text,
  SimpleGrid,
  Link,
  Select,
  Button,
} from "@chakra-ui/react";
import "./App.css";
import Logo from "./logo.jpg";
import { FaSquareXTwitter, FaDiscord } from "react-icons/fa6";
import { IoMdArrowRoundForward } from "react-icons/io";

import Medusa from "./billing-background-card.png";

// 2. Update the breakpoints as key-value pairs
const breakpoints = {
  base: "0px",
  sm: "320px",
  md: "768px",
  lg: "960px",
  xl: "1200px",
  "2xl": "1536px",
};

// 3. Extend the theme
const theme = extendBaseTheme({ breakpoints });

function App() {
  return (
    <ChakraBaseProvider theme={theme}>
      <Card
        // backgroundColor={"#1E90FF"}
        bgSize="cover"
        minHeight={"100vh"}
        bgImage={Medusa}
        paddingLeft={{ md: 24 }}
        paddingRight={{ md: 24 }}
      >
        {/* Header Section */}
        <Flex
          as="header"
          align="center"
          justify="space-between"
          padding={4}
          minWidth="100%"
          className="nav_bar"
        >
          {/* Logo */}
          <Box>
            <Image
              src={Logo}
              borderRadius={"100%"}
              alt="predictcoin"
              width={{ sm: "104px", md: "104px" }}
            />
          </Box>

          <SimpleGrid columns={{ sm: 5, md: 5 }} spacing={{ sm: 10, md: 10 }}>
            <Center
              opacity={{ sm: 0, md: 1 }}
              borderColor="#4169E1"
              borderRadius={8}
              borderWidth={{ sm: "0px", md: "3px" }}
              padding={2}
            >
              <Text fontSize={{ sm: "smaller" }} color={"white"}>
                Games
              </Text>
            </Center>

            <Center
              borderColor="#4169E1"
              borderRadius={8}
              justifyContent={"center"}
              alignItems={"center"}
              borderWidth={{ sm: "0px", md: "3px" }}
              padding={{ sm: 2, md: 2 }}
            >
              <Text fontSize={{ sm: "smaller" }} color={"white"}>
                Leaderboard
              </Text>
            </Center>

            <Center
              borderColor="#4169E1"
              borderRadius={8}
              borderWidth={{ sm: "0px", md: "3px" }}
              padding={2}
            >
              <Text fontSize={{ sm: "smaller" }} color={"white"}>
                Balance
              </Text>
            </Center>

            <Center
              opacity={{ sm: 0, md: 1 }}
              borderRadius={8}
              backgroundColor={"#4169E1"}
              padding={2}
            >
              <Text fontSize={{ sm: "smaller" }} color={"white"}>
                $FOMO
              </Text>
            </Center>

            <Center
              opacity={{ sm: 0, md: 1 }}
              borderRadius={8}
              backgroundColor={"#4169E1"}
              padding={2}
            >
              <Text color={"white"}>Connect Wallet</Text>
            </Center>
          </SimpleGrid>
        </Flex>

        <SimpleGrid marginTop={10} columns={{ sm: 1, md: 2 }} spacing={10}>
          <Center borderRadius={8} flexDirection="column" padding={8}>
            <Center
              borderColor="#4169E1"
              flexDirection="column"
              borderRadius={8}
              backgroundColor={"#6495ED"}
              borderWidth="3px"
              padding={4}
            >
              <Text marginBottom={3} fontSize="xx-small" color={"white"}>
                ADA balance 0.8747
              </Text>

              <Text marginBottom={2} fontSize="medium" color={"white"}>
                LIVE Price
              </Text>

              <Text marginBottom={5} fontSize="large" as={"b"} color={"white"}>
                $0.6152
              </Text>

              <Text marginBottom={1} fontSize="x-small" color={"white"}>
                CHART
              </Text>

              <Center
                backgroundColor={"#4169E1"}
                marginBottom={4}
                padding={2}
                borderRadius={8}
                flexDirection="column"
              >
                <Text marginBottom={3} fontSize="medium" color={"white"}>
                  Select Interval
                </Text>

                <SimpleGrid columns={3} spacing={10}>
                  <Center
                    borderColor="#4169E1"
                    borderRadius={8}
                    backgroundColor={"#6495ED"}
                    borderWidth="3px"
                    paddingY={2}
                    paddingLeft={{ sm: 3, md: 10 }}
                    paddingRight={{ sm: 3, md: 10 }}
                  >
                    <Text fontSize="x-small" color={"white"}>
                      2 min
                    </Text>
                  </Center>

                  <Center
                    borderColor="#4169E1"
                    borderRadius={8}
                    backgroundColor={"#6495ED"}
                    borderWidth="3px"
                    paddingY={2}
                    paddingLeft={{ sm: 3, md: 10 }}
                    paddingRight={{ sm: 3, md: 10 }}
                  >
                    <Text fontSize="x-small" color={"white"}>
                      3 min
                    </Text>
                  </Center>

                  <Center
                    borderColor="#4169E1"
                    borderRadius={8}
                    backgroundColor={"#6495ED"}
                    borderWidth="3px"
                    paddingY={2}
                    paddingLeft={{ sm: 3, md: 10 }}
                    paddingRight={{ sm: 3, md: 10 }}
                  >
                    <Text fontSize="x-small" color={"white"}>
                      5 min
                    </Text>
                  </Center>
                </SimpleGrid>
              </Center>

              <Center
                backgroundColor={"#4169E1"}
                padding={2}
                borderRadius={8}
                flexDirection="column"
              >
                <Text marginBottom={3} fontSize="medium" color={"white"}>
                  Select Amount
                </Text>

                <SimpleGrid columns={3} spacing={10}>
                  <Center
                    borderColor="#4169E1"
                    borderRadius={8}
                    backgroundColor={"blue.700"}
                    borderWidth="3px"
                    paddingY={2}
                    paddingLeft={{ sm: 3, md: 10 }}
                    paddingRight={{ sm: 3, md: 10 }}
                  >
                    <Text
                      textAlign={"center"}
                      fontSize={{ sm: "xx-small", md: "x-small" }}
                      color={"white"}
                    >
                      0.1 $ADA
                    </Text>
                  </Center>

                  <Center
                    borderColor="#4169E1"
                    borderRadius={8}
                    backgroundColor={"#6495ED"}
                    borderWidth="3px"
                    paddingY={2}
                    paddingLeft={{ sm: 3, md: 10 }}
                    paddingRight={{ sm: 3, md: 10 }}
                  >
                    <Text
                      textAlign={"center"}
                      fontSize={{ sm: "xx-small", md: "x-small" }}
                      color={"white"}
                    >
                      1 $ADA
                    </Text>
                  </Center>

                  <Center
                    borderColor="#4169E1"
                    borderRadius={8}
                    backgroundColor={"#6495ED"}
                    borderWidth="3px"
                    paddingY={2}
                    paddingLeft={{ sm: 3, md: 10 }}
                    paddingRight={{ sm: 3, md: 10 }}
                  >
                    <Text
                      textAlign={"center"}
                      fontSize={{ sm: "xx-small", md: "x-small" }}
                      color={"white"}
                    >
                      2 $ADA
                    </Text>
                  </Center>
                </SimpleGrid>
              </Center>

              <Button
                borderRadius={10}
                width={"100%"}
                padding={2}
                marginTop={7}
                backgroundColor={"green"}
              >
                <Text as={"b"} color={"white"} fontSize={"large"}>
                  BET UP
                </Text>
              </Button>

              <Button
                borderRadius={10}
                width={"100%"}
                padding={2}
                marginTop={4}
                backgroundColor={"red.800"}
              >
                <Text as={"b"} color={"white"} fontSize={"large"}>
                  BET DOWN
                </Text>
              </Button>
            </Center>

            <Text as={"b"} color={"white"} fontSize={"large"}>
              Gambling meta + First rev share token on Cardano
            </Text>

            <Text as={"b"} color={"white"} fontSize={"x-large"}>
              Let’s make $ADA great again
            </Text>

            <Button
              marginTop={10}
              backgroundColor={"#0000FF"}
              padding={3}
              flexDirection={"row"}
              borderRadius={4}
            >
              <Text as={"b"} marginRight={4} color={"white"} fontSize={"small"}>
                Play Now
              </Text>
              <IoMdArrowRoundForward color="white" />
            </Button>
          </Center>

          <Center borderRadius={8} flexDirection="column" padding={8}>
            <Center
              borderColor="#4169E1"
              flexDirection="column"
              borderRadius={8}
              padding={4}
            >
              <Center
                marginBottom={4}
                padding={2}
                borderRadius={8}
                width={"100%"}
                flexDirection="column"
              >
                <Text
                  marginBottom={3}
                  as={"b"}
                  fontSize="xx-small"
                  color={"white"}
                >
                  Choose up to 4 faces
                </Text>

                <SimpleGrid columns={4} spacing={4}>
                  <Button
                    borderColor="blue.700"
                    borderRadius={8}
                    height={10}
                    width={10}
                    borderWidth="3px"
                    padding={1}
                  ></Button>

                  <Button
                    borderColor="blue.700"
                    borderRadius={8}
                    height={10}
                    width={10}
                    borderWidth="3px"
                    padding={1}
                  ></Button>

                  <Button
                    borderColor="blue.700"
                    borderRadius={8}
                    height={10}
                    width={10}
                    borderWidth="3px"
                    padding={1}
                  ></Button>

                  <Button
                    borderColor="blue.700"
                    borderRadius={8}
                    height={10}
                    width={10}
                    borderWidth="3px"
                    padding={1}
                  ></Button>
                </SimpleGrid>
              </Center>

              <Center
                borderColor="blue.700"
                padding={2}
                borderRadius={8}
                flexDirection="column"
              >
                <Text marginBottom={3} fontSize="xx-small" color={"white"}>
                  Select Amount
                </Text>

                <SimpleGrid columns={3} spacing={10}>
                  <Center
                    borderColor="#4169E1"
                    borderRadius={8}
                    backgroundColor={"blue.700"}
                    borderWidth="3px"
                    paddingY={2}
                    paddingLeft={{ sm: 3, md: 10 }}
                    paddingRight={{ sm: 3, md: 10 }}
                  >
                    <Text
                      textAlign={"center"}
                      fontSize={{ sm: "xx-small", md: "x-small" }}
                      color={"white"}
                    >
                      0.1 $ADA
                    </Text>
                  </Center>

                  <Center
                    borderColor="#4169E1"
                    borderRadius={8}
                    backgroundColor={"#6495ED"}
                    borderWidth="3px"
                    paddingY={2}
                    paddingLeft={{ sm: 3, md: 10 }}
                    paddingRight={{ sm: 3, md: 10 }}
                  >
                    <Text
                      textAlign={"center"}
                      fontSize={{ sm: "xx-small", md: "x-small" }}
                      color={"white"}
                    >
                      1 $ADA
                    </Text>
                  </Center>

                  <Center
                    borderColor="#4169E1"
                    borderRadius={8}
                    backgroundColor={"#6495ED"}
                    borderWidth="3px"
                    paddingY={2}
                    paddingLeft={{ sm: 3, md: 10 }}
                    paddingRight={{ sm: 3, md: 10 }}
                  >
                    <Text
                      textAlign={"center"}
                      fontSize={{ sm: "xx-small", md: "x-small" }}
                      color={"white"}
                    >
                      2 $ADA
                    </Text>
                  </Center>
                </SimpleGrid>
              </Center>
            </Center>
          </Center>
        </SimpleGrid>

        <Box as="footer" mt={8} textAlign="center">
          <Flex
            as="footer"
            align="center"
            justify="space-between"
            padding={4}
            minWidth="100%"
            borderBottom="1px solid"
            borderColor="gray.200"
          >
            <Text
              onClick={() => {
                document.execCommand(
                  "copy",
                  true,
                  "1aedec14e2ca0868a0e00ca2d5344a291ebd0ba41b86438a8204502b"
                );
                alert("copied");
              }}
              fontSize={"small"}
              as={"b"}
              color={"white"}
            >
              Policy ID:
              1aedec14e2ca0868a0e00ca2d5344a291ebd0ba41b86438a8204502b
            </Text>

            <Box mx={2}>
              <SimpleGrid columns={2} spacing={10}>
                <Link href="https://x.com/predictcoinada?s=21" isExternal>
                  <FaSquareXTwitter size={30} color="white" />
                </Link>
                <Link href="/" isExternal>
                  <FaDiscord size={30} color="white" />
                </Link>
              </SimpleGrid>
            </Box>
          </Flex>
        </Box>
      </Card>
    </ChakraBaseProvider>
  );
}

export default App;
